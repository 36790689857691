import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { observer } from "mobx-react";
// import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
// import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import Contact from '../icons/contact';
import Cart from '../icons/cart';
import MenuIcon from '../../../ui-treact/components/svgs/MenuIcon'
import CloseIcon from '../../../ui-treact/components/svgs/Cross'
import { Link } from "../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general.js";
import Store from 'src/stores/common-store';

const InnerHeader = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

const Header = tw.header` w-full `;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export let NavLink = tw(Link)`
  text-xl my-2 lg:text-sm lg:mx-6 lg:my-0
   tracking-wide transition duration-300 
  pb-1 border-b-2 border-transparent  hocus:text-brand-900 hocus:no-underline	
`;
NavLink = styled(NavLink)`
  font-family: 'Harmonia Sans','Nunito Sans', sans-serif;
  font-size: 16px;
`;
const LogoWrapper = styled.div`
${tw`h-12 w-auto`}
`;

export const PrimaryLink = tw(NavLink)`
  px-8 py-3  bg-brand-900 text-gray-100 font-medium
  hocus:bg-brand-900 hocus:text-gray-100  text-sm
  border-b-0 hocus:shadow-lg block
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! mb-0`};

  img {
    ${tw`h-12 w-auto`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-around`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const SvgIcon = styled(Link)`
  svg {
    stroke-width: 3px;
    stroke: black;
    width: 28px;
    height: 28px;
    fill: white;
    margin: 0px 20px;
    @media (max-width: 767px) {
      margin: 0px;
    }
  }
`;
export const SvgIconDiv = styled.div`
  cursor: pointer;
  svg {
    stroke-width: 3px;
    stroke: black;
    width: 28px;
    height: 28px;
    fill: white;
    margin: 0px 5px;
    @media (max-width: 767px) {
      margin: 0px;
    }
  }
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`fixed inset-x-0 top-0 z-10 p-8 mx-4 my-6 text-center text-gray-900 bg-white border rounded-lg lg:hidden`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

const MenuButtonMobile = styled.div`
  a {
    display: block;
    padding: 0px;
    text-align: center;
    font-size: 13px;
    padding: 5px 10px;
    max-width: 175px;
  }
`;
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-around items-center
`;

export default observer (
  ({
    roundedHeaderButton = false,
    logoLink,
    links,
    primaryNavLinks,
    className,
    collapseBreakpointClass = 'lg',
    logo,
  }) => {

    const defaultLinks = [
      <NavLinks key={1}>
        <NavLink href="/#">About</NavLink>
        <NavLink href="/#">Blog</NavLink>
        <NavLink href="/#">Pricing</NavLink>
        <NavLink href="/#">Contact Us</NavLink>
        <NavLink href="/#" tw="lg:ml-12!">
          Login
        </NavLink>
        <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href="/#">
          Sign Up
        </PrimaryLink>
      </NavLinks>,
    ];

    const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
    const collapseBreakpointCss =
      collapseBreakPointCssMap[collapseBreakpointClass];

    const defaultLogoLink = (
      <LogoLink to="/">
        <img src={logo} alt="logo" />
      </LogoLink>
    );

    logoLink = logoLink || defaultLogoLink;
    links = links || defaultLinks;

    return ( 
      <Header className={className || 'header-light'}>
        <InnerHeader>
          <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
            {logoLink}
            <div class="flex justify-around items-center absolute right-0 mx-4">
            {links}
            <div className="flex"> 
              <SvgIcon to="/account">
                <Contact />
              </SvgIcon>
              <SvgIconDiv
                className="cart-icon-count pointer"
                onClick={() => {
                  Store.showCart();
                }} 
              >
                <Cart />
              </SvgIconDiv>
            </div>
            </div>

          </DesktopNavLinks>

          <MobileNavLinksContainer
            css={collapseBreakpointCss.mobileNavLinksContainer}
          >
            <LogoWrapper>{logoLink}</LogoWrapper>
            <MenuButtonMobile>{primaryNavLinks}</MenuButtonMobile>
            {/* <MobileNavLinks
              initial={{ x: '150%', display: 'none' }}
              animate={animation}
              css={collapseBreakpointCss.mobileNavLinks}
            >
              {links}
            </MobileNavLinks>
            <NavToggle
              onClick={toggleNavbar}
              className={showNavLinks ? 'open' : 'closed'}
            >
              <SvgIcon className="menu–mobile">
                {!showNavLinks ? <MenuIcon /> : <CloseIcon />}
              </SvgIcon>
            </NavToggle> */}
          </MobileNavLinksContainer>
        </InnerHeader>
      </Header>
    );
  }
);
 
/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
